.UserLogin-container {
    display: flex;
    height: 100vh;
}

.image-half {
    width: 60%;
    background-image: url('../../assets/vu-cc-min.webp'); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
}

.form-half {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserLogin-form {
    width: 400px;
    text-align: left;
}

.UserLogin-form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; /* Distributes space evenly between, before, and after items */
    align-items: center;
    box-sizing: border-box;
    height: 100%;
}

.powered-by {
    font-size: 12px;
    color: #888;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

@media (max-width: 991px) {
    #UserLogin-illustration-placeholder {
        display: none;
    }
    .form-half {
        width: 100%;
    }

    .UserLogin-form {
        width: 300px;
    }
    .addPaddingForMobile {
        padding: 0 20px;
    }
}
