.UserNameAndLogoutButton {
    margin-top: -10px;
    display: flex;
    align-items: center;
    align-content: center;
}

@media (max-width: 991px) {
    .KosLogoOnlyForDesktop {
        display: none;
    }
    .UserNameAndLogoutButton {
        margin-top: 0px;
    }
}
