@import url('../font/css/satoshi.css');

.KosModalFormLayout {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    align-content: center;
    gap: 10px 10px;
}
.KosModalFormLayout.Alternative {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    align-content: center;
    gap: 0 10px;
}

.KosFormLayout {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: baseline;
    align-content: center;
    gap: 10px 10px;
    background: white;
}

.KosFormLayout.Alternative {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    align-content: center;
    gap: 0 10px;
    padding: 10px;
}

@media (max-width: 991px) {
    .KosFormLayout {
        grid-template-columns: minmax(0, 1fr);
    }

    .KosModalFormLayout {
        grid-template-columns: minmax(0, 1fr);
    }
}

table.KosTable,
table.KosTable th,
table.KosTable td {
    border: 1px solid #dddddd;
    border-collapse: collapse;
    padding: 5px;
}
.box {
    width: 50px; /* Adjust width as needed */
    background-color: #ffebeb; /* Background color */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Position relative for absolute positioning of rotated text */
    border-radius: 4px; /* Optional: to match the SVG rect's rounded corners */
}

.rotated-text {
    transform: rotate(90deg); /* Rotate text by 90 degrees */
    position: absolute; /* Position absolute to center text */
    white-space: nowrap; /* Prevent text from wrapping */
    left: 50%; /* Center horizontally */
    top: 50%; /* Center vertically */
    transform: translate(-50%, -50%) rotate(270deg); /* Translate to center and rotate */
    color: #a62a37; /* Text color */
    font-weight: 700;
    font-size: 16px; /* Font size */
    text-transform: uppercase;
}

.underline-down {
    position: relative;
    display: inline-block;
    font-weight: 700;
    font-size: 17px;
    color: '#A62A37';
    line-height: '21.6px';
}

.underline-down::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px; /* Adjust this value to move the underline up or down */
    height: 2px; /* Adjust this value to change the thickness of the underline */
    background-color: #a62a37; /* Change the underline color */
    transform: scaleX(1);
    transform-origin: bottom left;
}

.UnderlineOdd:nth-child(odd) {
    border-bottom: 1px solid #d9d9d9; /* Adjust the thickness and color as needed */
}
.carousel-item {
    padding: 0 10px;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
    font-size: inherit;
    color: currentColor;
}

.CarouselCardCommon {
    border-radius: 6px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    cursor: pointer;
    background-color: #fbf7f6;
}

.CarouselCardInactive {
    border: 1px solid #d9d9d9;
}

.CarouselCardActive {
    border: 1px solid #a62a37;
}

.CarouselCardSection {
    font-size: 10px;
    font-weight: 700;
    line-height: 13.5px;
    letter-spacing: 0.1em;
    text-align: left;
    margin-bottom: 0px;
}

.CarouselCardDateRange {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.2px;
    text-align: left;
    margin-top: 3px;
}
