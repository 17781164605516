.courseFilterAndCourseView {
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.showOnMobile {
    display: none;
}

@media (max-width: 991px) {
    .courseFilterAndCourseView {
        grid-template-columns: 1fr;
    }
    .courseFilterHideAboveALimit {
        display: none;
    }
    .showOnMobile {
        display: block;
    }
}
